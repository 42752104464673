import PicCardSlider from './components/pages/product/PicCardSlider';
import { CartPage } from './components/pages/cart/cartPage';

export const Test = () => {
  return (
    <>
      <CartPage />
    </>
  );
};
