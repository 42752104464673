import pin from '../../../assets/images/home/pin.png';
import Clamp from '../../../assets/images/home/clamp.png';
import Post from '../../../assets/images/home/post.png';

export const latestProducts = [
  {
    image: pin,
    productName: 'Brake Pins',
    cost: 140,
    offer: '',
    alt: 'Customized Brake Pins for a BMX bike',
    key: 'pin',
  },
  {
    image: Clamp,
    productName: 'Seat Clamp',
    cost: 300,
    offer: '',
    alt: 'A Seat Clamp for a BMX bike',
    key: 'clamp',
  },

  {
    image: Post,
    productName: 'Seat post',
    cost: 130,
    offer: 125.0,
    alt: 'Seat post for a BMX bike',
    key: 'post',
  },
];
